export enum ResponseCode {
  Success = '200',
  BadRequest = '400',
  Unauthorized = '401',
  UserForbidden = '403',
  InvalidFunction = '404',
  DuplicationRequest = '409',
  InternalServerError = '500',
  RequestTimeout = '504',
  ExternalServerError = '505',
  InvalidSignature = '003',
  InvalidDateTime = '007',
  InvalidUsernameOrPassword = '100',
  UserIsInactive = '101',
  UserIsLocked = '102',
  BusinessUnitSuspended = '103',
  BUCodeExist = '104',
  PhoneNumberExist = '105',
  ExpiredPassword = '114',
  UserRequireChangePassword = '115',
  BINNoExists = '008',
  BUDeclaredService = '009',
}

export type ResponseStatus = keyof typeof ResponseCode;
